import { useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import bottomimg from "../../../assets/images/sidebar/Bottom.svg";
import { detailsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
// components
import ConfirmModal from "../modals/ConfirmModal";
import SucessModal from "../modals/SucessModal";
import VideoModal from "../../genaral/VideoModal";
import { useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import Mediamodal from "../../genaral/Mediamodal";
function VenueDetails({
    infoBar,
    setInfobar,
    selectedVenue,
    venueReload,
    setVenueReload,
    isModal,
    setModal,
    currentVideo,
    setCurrentVideo,
    setActiveVideo,
    activeVideo,
}) {
    const [isVerified, setIsVerified] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [validation, setValidation] = useState(false);
    const [sucessModal, setSucessmodal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    // const [isModal, setModal] = useState(false);
    const [isImageViewer, setImageViewer] = useState(false);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    const [venueSection, setVenueSection] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [venueDetails, setVenueDetails] = useState({});

    const [venueImages, setVenueImages] = useState([]);
    const [venueVideos, setVenueVideos] = useState([]);
    const [venueFacultyDetails, setVenueFacultyDetails] = useState([]);
    // const [currentVideo, setCurrentVideo] = useState("");

    const handleImageViewer = () => {
        setImageViewer((prev) => !prev);
    };
    console.log(venueDetails, "venudetails");
    console.log(venueImages, "venueImages");
    console.log(venueVideos, "venueVideos");
    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    useEffect(() => {
        selectedVenue.id && venue();
    }, [infoBar, confirmModal, sucessModal]);

    const venue = (item) => {
        setLoading(true);
        detailsConfig
            .get(`api/v1/web/venue/${selectedVenue.id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setLoading(false);
                    setVenueDetails(data);
                    setVenueFacultyDetails(
                        data.venue_facility_details.filter(
                            (item) => item.is_available === true
                        )
                    );

                    // setVenueVideos(
                    //     data.venue_images.filter(
                    //         (data) => data.type === "video"
                    //     )
                    // );
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleVenueClick = (data) => {
        setLoading(true);
        detailsConfig
            .post(
                `api/v1/web/set-venue-verification-status/${venueDetails.id}/`,
                {
                    status: data,
                },

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const previousImage = () => {
        setCurrentImgIndex(currentImgIndex - 1);
    };
    const nextImage = () => {
        setCurrentImgIndex(currentImgIndex + 1);
    };

    console.log(venueImages, "iioii....");

    return (
        <>
            {/* <VenueContainer> */}
            <Mediamodal
                setModal={setModal}
                isModal={isModal}
                videoUrl={activeVideo}
            />
            <Cover>
                <Close onClick={() => setInfobar(false)}>
                    <Ximg
                        src={
                            require("../../../assets/images/sidebar/closeimg.svg")
                                .default
                        }
                        alt="close"
                    />
                </Close>
                <Container>
                    {/* <VideoModal
                        setModal={setModal}
                        isModal={isModal}
                        videoUrl={currentVideo}
                    /> */}
                    <ImgsViewer
                        imgs={venueImages}
                        currImg={currentImgIndex}
                        isOpen={isImageViewer}
                        onClickPrev={previousImage}
                        onClickNext={nextImage}
                        onClose={handleImageViewer}
                    />

                    <SubContainer>
                        <ProfileMain>
                            <ProfileImgContainer>
                                <ProfilePic
                                    src={require("../../../assets/images/venues/places/noplace.jpg")}
                                    alt="faculty-icon"
                                />
                            </ProfileImgContainer>
                            <Content>
                                <Details>
                                    <Name>{venueDetails.venue_name}</Name>
                                    <Designation>
                                        <PinContainer>
                                            <PinImg
                                                src={
                                                    require("../../../assets/images/venues/location.svg")
                                                        .default
                                                }
                                                alt="location"
                                            />
                                        </PinContainer>{" "}
                                        <Location>
                                            {venueDetails.map_location}
                                        </Location>
                                    </Designation>
                                </Details>
                                <StatusContainer>
                                    {venueDetails.is_approved ===
                                    "pending" ? null : venueDetails.is_approved ===
                                      "approved" ? (
                                        <VerifiedContainer>
                                            <VeifiedIcon
                                                src={
                                                    require("../../../assets/images/faculty/tick.svg")
                                                        .default
                                                }
                                                alt="verify"
                                            />
                                        </VerifiedContainer>
                                    ) : (
                                        <VerifiedContainer>
                                            <VeifiedIcon
                                                src={
                                                    require("../../../assets/images/faculty/cross.svg")
                                                        .default
                                                }
                                                alt="rejected"
                                            />
                                        </VerifiedContainer>
                                    )}
                                    <Result>
                                        {venueDetails.is_approved ===
                                        "pending" ? null : venueDetails.is_approved ===
                                          "approved" ? (
                                            <Status>verified</Status>
                                        ) : (
                                            <Reject>
                                                {venueDetails.is_approved}
                                            </Reject>
                                        )}
                                    </Result>
                                </StatusContainer>
                            </Content>
                        </ProfileMain>
                        <BorderLine></BorderLine>
                        <BioContainer>
                            <BioHead>Facilities</BioHead>
                            <FacilityList>
                                {venueDetails.facility?.map((item, id) => {
                                    return (
                                        <Facility>
                                            {item.facility}
                                            <span>{item.count}</span>
                                        </Facility>
                                    );
                                })}

                                {/* ))} */}

                                {/* <Facility>Parking</Facility>
                                <Facility>LED Wall</Facility>
                                <Facility>Gallery</Facility> */}
                            </FacilityList>
                        </BioContainer>
                        <AvailableDate>
                            <MediaHeading>Available Date and Time</MediaHeading>
                            <LinkDiv>
                                {" "}
                                <DateAvailable>12/10/2022</DateAvailable>
                                <DateAvailable>After noon</DateAvailable>
                            </LinkDiv>
                        </AvailableDate>
                    </SubContainer>
                    <LocationDetails>
                        <Item>
                            <Heading>Contact Person</Heading>
                            <ParaDetail>{venueDetails.contact_name}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Email ID</Heading>
                            <Email>{venueDetails.email}</Email>
                        </Item>
                        <Item>
                            <Heading>District</Heading>
                            <ParaDetail>{venueDetails.district}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Mobile Number</Heading>
                            <ParaDetail>{venueDetails.phone}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Place</Heading>
                            <ParaDetail>{venueDetails.place}</ParaDetail>
                        </Item>
                    </LocationDetails>
                    {/* {venueDetails.drive_link?.length > 0 ? (
                        <LinkCont>
                            <LinkHead>Links</LinkHead>
                            {venueDetails.drive_link?.map((data) => (
                                <LinkDiv
                                    href="https://www.flaticon.com/free-icon/link_154843?term=link&page"
                                    target="_blank"
                                >
                                    <Clip>
                                        <ImageClip
                                            src={require("../../../assets/images/sidebar/clip.png")}
                                            alt="faculty-icon"
                                        />
                                    </Clip>
                                    <Url>{data.link}</Url>
                                </LinkDiv>
                            ))}
                        </LinkCont>
                    ) : (
                        ""
                    )} */}

                    <ImageContainer>
                        <ContentList>
                            {venueDetails.venue_image?.map((data, index) =>
                                data?.image?.includes(".mp4") ? (
                                    <VideoThumbnail
                                        onClick={() => {
                                            setModal(true);
                                            // setCurrentVideo(data.image);
                                            setActiveVideo(data.image);
                                        }}
                                    >
                                        <ThumbContainer>
                                            <ThumbnailImg
                                                src={
                                                    require("../../../assets/images/sidebar/video-thumb.svg")
                                                        .default
                                                }
                                                alt="video-thumb"
                                            />
                                        </ThumbContainer>
                                        <PlayButton
                                            onClick={() => setModal(true)}
                                        >
                                            <PlayImg
                                                src={
                                                    require("../../../assets/images/sidebar/play-button.svg")
                                                        .default
                                                }
                                                alt="play-button"
                                            />
                                        </PlayButton>
                                    </VideoThumbnail>
                                ) : (
                                    <ItemImages
                                        // key={index}
                                        onClick={() => {
                                            setImageViewer(true);
                                            setCurrentImgIndex(index);
                                            venueDetails.venue_image.forEach(
                                                (element) => {
                                                    venueImages.push({
                                                        src: element.image,
                                                    });
                                                }
                                            );
                                        }}
                                    >
                                        <MediaImg
                                            src={data.image}
                                            alt="Image"
                                        />
                                    </ItemImages>
                                )
                            )}
                        </ContentList>
                    </ImageContainer>
                    <VenueBottom>
                        {venueDetails.is_approved === "approved" ? (
                            <CancelVerifyBtn
                                onClick={() => {
                                    setConfirmModal(true);
                                    setValidation(false);
                                    setVenueSection(true);
                                }}
                            >
                                Cancel verification
                            </CancelVerifyBtn>
                        ) : venueDetails.is_approved === "rejected" ? (
                            <Cancelled
                            // onClick={() => {
                            //     setConfirmModal(true);
                            //     setValidation(true);
                            //     setVenueSection(true);
                            // }}
                            >
                                Rejected
                            </Cancelled>
                        ) : (
                            <ButtonContainer>
                                <RejectButton
                                    onClick={() => {
                                        setConfirmModal(true);
                                        setValidation(true);
                                        setVenueSection(true);
                                    }}
                                >
                                    Reject
                                </RejectButton>
                                <VerifyButton
                                    onClick={() => {
                                        setSucessmodal(true);
                                        setVenueSection(true);
                                        handleVenueClick("approved");
                                        console.log(
                                            "venuedetails successmodal",
                                            sucessModal
                                        );
                                    }}
                                >
                                    Verify
                                </VerifyButton>
                            </ButtonContainer>
                        )}
                    </VenueBottom>
                    <SucessModal
                        setInfobar={setInfobar}
                        setIsVerified={setIsVerified}
                        sucessModal={sucessModal}
                        setSucessmodal={setSucessmodal}
                        venueReload={venueReload}
                        setVenueReload={setVenueReload}
                        description={"You have successfully verified the venue"}
                    />
                    <ConfirmModal
                        setInfobar={setInfobar}
                        setIsVerified={setIsVerified}
                        setIsRejected={setIsRejected}
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        validation={validation}
                        venueDetails={venueDetails}
                        venueSection={venueSection}
                        venueReload={venueReload}
                        setVenueReload={setVenueReload}
                    />
                </Container>
                {/* </VenueContainer> */}
            </Cover>
        </>
    );
}

export default VenueDetails;
const Cover = styled.div`
    position: relative;
`;
// const VenueContainer = styled.div`
//     position: fixed;
//     top: 0;
//     right: 0%;
//     width: 35%;
//     height: 100%;
//     background: #1f2934;
//     z-index: 1000;
//     @media all and (max-width: 1280px) {
//         width: 40%;
//     }
//     @media all and (max-width: 980px) {
//         width: 58%;
//     }
//     @media all and (max-width: 768px) {
//         width: 75%;
//     }
//     @media all and (max-width: 640px) {
//         width: 100%;
//     }
// `;
const Container = styled.div`
    z-index: 41;
    overflow: scroll;
    color: white;
    padding: 25px 25px 0;
    overflow-y: scroll;
    max-height: 100vh;
    z-index: 99;
    background-color: #1f2934;
    min-height: 100vh;
    box-shadow: rgba(99, 99, 99, 0) 0px 2px 8px 0px;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 360px) {
        padding: 20px 20px 0;
    }
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 84%;
`;
const Close = styled.div`
    cursor: pointer;
    width: 22px;
    position: absolute;
    top: 20px;
    left: -33px;
    z-index: 0;
    @media all and (max-width: 640px) {
        left: -23px;
    }
    @media all and (max-width: 360px) {
        left: -21px;
    }
`;
const Ximg = styled.img``;
const ProfileMain = styled.div`
    display: flex;
    margin: 15px 0px 30px;
    align-items: center;
    justify-content: space-between;
`;
const ProfileImgContainer = styled.div`
    width: 65px;
    min-height: 65px;
    min-width: 65px;
    max-height: 65px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    @media all and (max-width: 360px) {
        width: 58px;
        min-height: 58px;
        min-width: 58px;
        max-height: 58px;
    }
`;
const ProfilePic = styled.img`
    /* height: 70px;
    object-fit: cover;
    width: 70px; */
    width: 100%;
    height: 100%;
    display: block;
`;
const Details = styled.div`
    /* margin-left: 15px; */
    width: 83%;
`;
const StatusContainer = styled.div`
    /* margin-left: auto; */
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const VerifiedContainer = styled.div`
    width: 20px;
    text-align: center;
    margin-bottom: 5px;
`;
const VeifiedIcon = styled.img``;
const Result = styled.span``;
const Reject = styled.p`
    font-size: 12px;
    color: #fe673a;
    text-transform: capitalize;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const BorderLine = styled.hr`
    border: 0.5px solid #3b3b3b;
    width: 100%;
`;
const SubContainer = styled.div`
    font-size: 14px;
`;
const Name = styled.p`
    color: #fff;
    font-size: 16px;
`;
const Designation = styled.div`
    display: flex;
    color: #28cc8b;
    font-size: 14px;
    justify-content: space-between;
`;
const BioContainer = styled.div`
    color: #ffff;
    margin: 30px 0px 15px;
`;
const BioHead = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
`;
const FacilityList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
`;
const Facility = styled.li`
    font-size: 14px !important;
    background: #14232e;
    padding: 6px;
    margin: 0px 5px 7px 0px;
    border-radius: 6px;
    &:last-child {
        margin-right: 0;
    }
    span {
        margin-left: 5px;
        color: #28cc8b;
        font-family: "gordita_medium";
    }
`;
const LocationDetails = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
`;
const Item = styled.li`
    font-size: 15px !important;
    margin-bottom: 15px;
`;

const Heading = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 2px;
`;
const ParaDetail = styled.p`
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
`;
const Email = styled.p`
    font-size: 14px;
    color: #fff;
`;
const LinkCont = styled.div`
    margin: 15px 0px 15px;
`;
const LinkHead = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 5px;
`;
const LinkDiv = styled.a`
    padding: 6px;
    width: 50%;
    background-color: #14232e;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;
const Clip = styled.div`
    cursor: pointer;
    background: #1f2934;
    padding: 3px;
    border-radius: 6px;
    width: 6%;
`;
const ImageClip = styled.img`
    display: block;
    width: 100%;
`;
const Url = styled.span`
    font-size: 12px;
    margin-left: 12px;
    color: #fff;
`;

const ImageContainer = styled.div`
    margin-bottom: 20px;
`;
const ContentList = styled.ul`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
`;
const ItemImages = styled.li``;
const VenueBottom = styled.div`
    background: #1f2934;
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 100%;
    padding: 10px 25px 25px;
    cursor: pointer;
`;
const CancelVerifyBtn = styled.button`
    cursor: pointer;
    width: 100%;
    padding: 9.5px;
    border: 1.5px solid #fe673a;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
`;
const Cancelled = styled.div`
    cursor: default;
    width: 100%;
    padding: 9.5px;
    font-size: 14px;
    background: #fe673a;
    color: #fff;
    border-radius: 8px;
    text-align: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    text-align: center;
    font-size: 14px;
`;
const RejectButton = styled.div`
    border-radius: 6px;
    border: 1px solid #fe673a;
    padding: 8px 6px;
    width: 50%;
    margin-right: 10px;
    cursor: pointer;
`;
const VerifyButton = styled.div`
    border-radius: 6px;
    border: 1px solid #28cc8b;
    padding: 8px 6px;
    width: 50%;
    background: #28cc8b;
    cursor: pointer;
`;
const MediaImg = styled.img`
    height: 93px;
    object-fit: cover;
`;
const VideoThumbnail = styled.div`
    position: relative;
    cursor: pointer;
`;
const ThumbContainer = styled.div``;
const ThumbnailImg = styled.img``;
const PlayButton = styled.div`
    position: absolute;
    top: 32%;
    left: 35%;
    width: 35%;
    cursor: pointer;
`;
const PlayImg = styled.img``;
const PinContainer = styled.span`
    display: inline-block;
    width: 15px;
    margin-right: 3px;
`;
const PinImg = styled.img``;
const Location = styled.p`
    color: #28cc8b;
    font-size: 12px;
    margin-bottom: 5px;
    width: 94% !important;
    word-break: break-all;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const Status = styled.h4`
    text-align: center;
    color: #28cc8b;
    text-transform: capitalize;
    font-size: 12px;
`;
const AvailableDate = styled.div`
    margin: 15px 0;
`;
const DateAvailable = styled.p`
    font-size: 14px;
    margin: 0 auto;
    color: #fff;
`;
const MediaHeading = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 10px;
    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;
