import { useContext, useState } from "react";
import styled from "styled-components";
import bottomimg from "../../../assets/images/sidebar/Bottom.svg";
// components
import SucessModal from "../modals/SucessModal";
import ConfirmModal from "../modals/ConfirmModal";

import { detailsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import { data } from "jquery";
import { getDateStr } from "../../helpers/functions";
import { type } from "@testing-library/user-event/dist/type";
import MediaModal from "../../genaral/Mediamodal";
import { saveAs } from "file-saver";

function FacultyDetails({
    infoBar,
    setInfobar,
    selectedFaculty,
    reload,
    setReload,
    isModal,
    setModal,
    setActiveVideo,
    activeVideo,
}) {
    const [isRejected, setIsRejected] = useState(false);
    const [validation, setValidation] = useState(false);
    const [sucessModal, setSucessmodal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isImageViewer, setImageViewer] = useState(false);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const [is_modal_active, setModalActive] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [facultyDetails, setFacultyDetails] = useState({});
    const [facultyMediaImages, setFacultyMediaImages] = useState([]);
    const [facultyMediaVideo, setFacultyMediaVideo] = useState([]);
    const currentDate = new window.Date();
    const isImage = [".gif", ".jpg", ".jpeg", ".png"]; //you can add more
    const isVideo = [".mpg", ".mp2", ".mpeg", ".mpe", ".mpv", ".mp4"];
    const [imageUrl, setImageUrl] = useState(
        "https://ekerala-41c203b2-9dd9-700ef917dcde.ekerala.demo.talrop.works/media/faculty-images/Image0388.gif"
    );
    // const [isModal, setModal] = useState(false);
    // const [activeVideo, setActiveVideo] = useState("");
    const [socialmedia, setSocialMedia] = useState([]);
    const [driveLink, setDriveLink] = useState([]);
    const handleImageViewer = () => {
        setImageViewer((prev) => !prev);
    };
    // const handleModal = (videoItem) => {

    //     setTimeout(() => {
    //         setModalActive((prev) => !prev);
    //     }, 500);
    // };

    const [facultymedia, setFacultymedia] = useState([]);

    useEffect(() => {
        selectedFaculty.id && faculty();
    }, [infoBar, confirmModal, sucessModal]);

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const faculty = (item) => {
        setLoading(true);
        detailsConfig
            .get(`api/v1/web/faculty/${selectedFaculty.id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setLoading(false);
                    setFacultyDetails(data);
                    setFacultyMediaImages(response.data.data);
                    setFacultyMediaVideo(response.data.data);
                    // setFacultyMediaVideo(
                    //     data.faculty_image.filter(
                    //         (data) => data.type === "video"
                    //     )
                    // );
                    // setFacultyMediaImages(
                    //     data.faculty_image.filter(
                    //         (data) => data.type === "image"
                    //     )
                    // );

                    setSocialMedia(data.social_media);
                    setDriveLink(data.drive_link);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                } else {
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const handleFacultyClick = (data) => {
        setLoading(true);
        detailsConfig
            .post(
                `api/v1/web/set-faculty-verification-status/${facultyDetails.id}/`,
                {
                    status: data,
                },

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    // setReload(!reload);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const previousImage = () => {
        setCurrentImgIndex(currentImgIndex - 1);
    };
    const nextImage = () => {
        setCurrentImgIndex(currentImgIndex + 1);
    };
    console.log(facultyDetails, "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
    // const handleDownload = () => {
    //     const link = document.createElement("a");
    //     link.href = imageUrl;
    //     link.download = "image.jpg";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    // async function downloadImage(url) {
    //     const response = await fetch(url);
    //     const blob = await response.blob();
    //     const imageUrl = URL.createObjectURL(blob);
    //     const imgElement = document.createElement("img");
    //     imgElement.src = imageUrl;
    //     document.body.appendChild(imgElement);
    // }

    // const downloadFile = () => {
    //     console.log("#######################################");
    //     const link = document.createElement("a");
    //     link.href = imageUrl;
    //     link.download = "image.jpg";
    //     // document.body.appendChild(link);
    //     link.click();
    //     // document.body.removeChild(link);
    // };
    return (
        <Cover>
            <Close onClick={() => setInfobar(false)}>
                <img
                    src={
                        require("../../../assets/images/sidebar/closeimg.svg")
                            .default
                    }
                    alt="close"
                />
            </Close>
            {/* <CloseTwo>
                <img
                    src={
                        require("../../../assets/images/sidebar/closeimg.svg")
                            .default
                    }
                    alt="close"
                />
            </CloseTwo> */}

            <Container>
                {/* <VideoModal
                    setModal={setModal}
                    isModal={isModal}
                    videoUrl={activeVideo}
                /> */}
                <MediaModal
                    setModal={setModal}
                    isModal={isModal}
                    videoUrl={activeVideo}
                />
                <ImgsViewer
                    imgs={facultymedia}
                    currImg={currentImgIndex}
                    isOpen={isImageViewer}
                    onClickPrev={previousImage}
                    onClickNext={nextImage}
                    onClose={handleImageViewer}
                />
                {/* {isImageViewer && <Download></Download>} */}
                <SubContainer>
                    <ProfileMain>
                        <ProfileContainer>
                            <ProfileImgContainer>
                                <ProfilePic
                                    src={require("../../../assets/images/faculty/person/noimage.jpg")}
                                    alt="faculty-icon"
                                />
                            </ProfileImgContainer>
                        </ProfileContainer>
                        <Content>
                            <Details>
                                <Name>{facultyDetails.name}</Name>
                                <Designation>
                                    {facultyDetails.profession}
                                </Designation>
                            </Details>
                            <StatusContainer>
                                {facultyDetails.faculty_status ===
                                "pending" ? null : facultyDetails.faculty_status ===
                                  "approved" ? (
                                    <VerifiedContainer>
                                        <VeifiedIcon
                                            src={
                                                require("../../../assets/images/faculty/tick.svg")
                                                    .default
                                            }
                                            alt="verify"
                                        />
                                    </VerifiedContainer>
                                ) : (
                                    <VerifiedContainer>
                                        <VeifiedIcon
                                            src={
                                                require("../../../assets/images/faculty/cross.svg")
                                                    .default
                                            }
                                            alt="rejected"
                                        />
                                    </VerifiedContainer>
                                )}
                                <Result>
                                    {facultyDetails.faculty_status ===
                                    "pending" ? null : facultyDetails.faculty_status ===
                                      "approved" ? (
                                        <Status>verified</Status>
                                    ) : (
                                        <Reject>
                                            {facultyDetails.faculty_status}
                                        </Reject>
                                    )}
                                </Result>
                            </StatusContainer>
                        </Content>
                    </ProfileMain>
                    <BorderLine></BorderLine>
                    <BioContainer>
                        <BioHead>Bio</BioHead>
                        <BioPara>{facultyDetails.bio}</BioPara>
                    </BioContainer>
                </SubContainer>
                <DetailsContainer>
                    <PersonDetails>
                        <Item>
                            <Heading>Mobile Number</Heading>
                            <ParaDetail> {facultyDetails.phone}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Email ID</Heading>
                            <Email> {facultyDetails.email}</Email>
                        </Item>
                        <Item>
                            <Heading>Address</Heading>
                            <ParaDetail> {facultyDetails.address}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Place</Heading>
                            <ParaDetail>{facultyDetails.place}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>District</Heading>
                            <ParaDetail>{facultyDetails.district}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Subject</Heading>
                            <ParaDetail>{facultyDetails.subject}</ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Available Date</Heading>
                            <ParaDetail>
                                {getDateStr(currentDate) ===
                                getDateStr(facultyDetails.available_date)
                                    ? "Today"
                                    : getDateStr(facultyDetails.available_date)}
                                {/* {facultyDetails.available_date} */}
                            </ParaDetail>
                        </Item>
                        <Item>
                            <Heading>Categories</Heading>
                            <ParaDetail>{facultyDetails.profession}</ParaDetail>
                        </Item>
                    </PersonDetails>
                </DetailsContainer>

                <SocialMediaCont>
                    {facultyDetails.social_media?.length > 0 ? (
                        <>
                            <MediaHeading> Social Media</MediaHeading>
                            <MediaList>
                                {facultyDetails.social_media?.map(
                                    (data, index) => (
                                        <MediaItem key={index}>
                                            <a href={data.link}>
                                                {data.social_media ===
                                                "Facebook" ? (
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sidebar/facebook.svg")
                                                                .default
                                                        }
                                                        alt="facebook"
                                                    />
                                                ) : data.social_media ===
                                                  "LinkedIn" ? (
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sidebar/linkidin.svg")
                                                                .default
                                                        }
                                                        alt="linkedin"
                                                    />
                                                ) : data.social_media ===
                                                  "Instagram" ? (
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sidebar/insta.svg")
                                                                .default
                                                        }
                                                        alt="instagram"
                                                    />
                                                ) : data.social_media ===
                                                  "Twitter" ? (
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sidebar/twitter.svg")
                                                                .default
                                                        }
                                                        alt="twitter"
                                                    />
                                                ) : data.social_media ===
                                                  "Whatsapp" ? (
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sidebar/whatsapp.svg")
                                                                .default
                                                        }
                                                        alt="whatsapp"
                                                    />
                                                ) : null}
                                            </a>
                                        </MediaItem>
                                    )
                                )}
                            </MediaList>
                        </>
                    ) : (
                        ""
                    )}
                </SocialMediaCont>
                <AvailableDate>
                    <MediaHeading>Available Date</MediaHeading>
                    <LinkDiv>
                        {" "}
                        <DateAvailable>12/10/2022</DateAvailable>
                    </LinkDiv>
                </AvailableDate>
                <PlaceDiV>
                    <MediaHeading>Place of preference</MediaHeading>
                    <LinkDiv>
                        {" "}
                        <DateAvailable>Thrissur</DateAvailable>
                    </LinkDiv>
                </PlaceDiV>
                <ModeOfSession>
                    <MediaHeading>Mode Of Session</MediaHeading>
                    <LinkDiv>
                        {" "}
                        <DateAvailable>Online</DateAvailable>
                    </LinkDiv>
                </ModeOfSession>

                {/* <LinkCont>
                    {facultyDetails.drive_link?.length > 0 ? (
                        <>
                            <LinkHead>Links</LinkHead>
                            {facultyDetails.drive_link?.map((data) => (
                                <>
                                    <LinkDiv
                                        href={
                                            "https://www.flaticon.com/free-icon/link_154843?term=link&page"
                                        }
                                        target="_blank"
                                    >
                                        <Clip>
                                            <ImageClip
                                                src={require("../../../assets/images/sidebar/clip.png")}
                                                alt="faculty-icon"
                                            />
                                        </Clip>
                                        <Url>{data.link}</Url>
                                    </LinkDiv>
                                </>
                            ))}
                        </>
                    ) : (
                        ""
                    )}
                </LinkCont> */}
                <ImageContainer>
                    {facultyMediaImages.faculty_image?.map((data, index) =>
                        data?.image?.includes(".mp4") ? (
                            <ContentList>
                                <Li>
                                    <VideoThumbnail
                                        onClick={() => {
                                            setModal(true);
                                            setActiveVideo(data.image);
                                        }}
                                    >
                                        <ThumbContainer>
                                            <ThumbnailImg
                                                src={require("../../../assets/images/sidebar/eKerala_Thumpnail.png")}
                                                alt="video-thumb"
                                            />
                                        </ThumbContainer>
                                        <PlayButton>
                                            <PlayImg
                                                src={
                                                    require("../../../assets/images/sidebar/play-button.svg")
                                                        .default
                                                }
                                                alt="play-button"
                                            />
                                        </PlayButton>
                                    </VideoThumbnail>
                                </Li>

                                {/* {facultyMediaImages.faculty_image?.map(
                    (data, index) => (
                        <>
                            <Li>
                                <ImageDiv>
                                    <ItemImages
                                        key={index}
                                        onClick={() => {
                                            setImageViewer(true);
                                            setCurrentImgIndex(index);
                                        }}
                                    >
                                        <MediaImg
                                            src={data.image}
                                            alt="Media-Img"
                                        />
                                    </ItemImages>
                                </ImageDiv>
                            </Li>
                        </>
                    )
                )} */}
                            </ContentList>
                        ) : (
                            <ContentList>
                                <Li>
                                    <ImageDiv
                                        onClick={() => {
                                            setImageViewer(true);
                                            setCurrentImgIndex(index);
                                            facultyDetails.faculty_image.forEach(
                                                (element) => {
                                                    facultymedia.push({
                                                        src: element.image,
                                                    });
                                                }
                                            );
                                        }}
                                    >
                                        <ItemImages>
                                            <MediaImg
                                                src={data.image}
                                                alt="image"
                                            />
                                        </ItemImages>
                                    </ImageDiv>
                                </Li>
                            </ContentList>
                        )
                    )}
                </ImageContainer>

                <FacultyBottom>
                    {facultyDetails.faculty_status === "approved" ? (
                        <CancelVerifyBtn
                            onClick={() => {
                                setConfirmModal(true);
                                setValidation(false);
                            }}
                        >
                            Cancel verification
                        </CancelVerifyBtn>
                    ) : facultyDetails.faculty_status === "rejected" ? (
                        <Cancelled
                        // onClick={() => {
                        //     setConfirmModal(true);
                        //     setValidation(true);
                        // }}
                        >
                            Rejected
                        </Cancelled>
                    ) : (
                        <ButtonContainer>
                            <RejectBttn
                                onClick={() => {
                                    setConfirmModal(true);
                                    setValidation(true);
                                }}
                            >
                                Reject
                            </RejectBttn>
                            <VerifyBttn
                                onClick={() => {
                                    setSucessmodal(true);

                                    handleFacultyClick("approved");
                                }}
                            >
                                Verify
                            </VerifyBttn>
                        </ButtonContainer>
                    )}
                </FacultyBottom>
                <SucessModal
                    setInfobar={setInfobar}
                    sucessModal={sucessModal}
                    setSucessmodal={setSucessmodal}
                    facultyDetails={facultyDetails}
                    reload={reload}
                    setReload={setReload}
                    description={"You have successfully verified the faculty"}
                />
                <ConfirmModal
                    setInfobar={setInfobar}
                    setIsRejected={setIsRejected}
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    validation={validation}
                    facultyDetails={facultyDetails}
                    reload={reload}
                    setReload={setReload}
                    // handleClick={handleClick}
                />
            </Container>
        </Cover>
    );
}

export default FacultyDetails;

const Container = styled.div`
    /* stock */
    /* position: relative; */
    z-index: 41;
    overflow: scroll;
    color: white;
    padding: 25px 25px 52px;
    overflow-y: scroll;
    max-height: 100vh;
    z-index: 99;
    background-color: #1f2934;
    box-shadow: rgba(99, 99, 99, 0) 0px 2px 8px 0px;
    min-height: 100vh;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 360px) {
        padding: 10px 10px 65px;
    }
`;
const Close = styled.div`
    cursor: pointer;
    width: 22px;
    position: absolute;
    top: 20px;
    left: -40px;
    z-index: 0;
    @media all and (max-width: 640px) {
        left: -22px;
    }
`;
const CloseTwo = styled.div`
    cursor: pointer;
    width: 22px;
    position: absolute;
    top: 30px;
    left: -40px;
    z-index: 0;
    @media all and (max-width: 640px) {
        left: -22px;
    }
`;
const img = styled.img``;
const ProfileMain = styled.div`
    display: flex;
    margin: 15px 0px 30px;
    align-items: center;
    justify-content: space-between;
`;
const ProfileContainer = styled.div`
    width: 68px;
    min-height: 68px;
    min-width: 68px;
    max-height: 68px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #28cc8b;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 84%;
`;
const ProfileImgContainer = styled.div`
    width: 62px;
    min-height: 62px;
    min-width: 62px;
    max-height: 62px;
    overflow: hidden;
    border-radius: 50%;
`;
const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    /* height: 70px;
    object-fit: cover;
    width: 70px; */
`;

const Details = styled.div`
    /* margin-left: 15px; */
    width: 70%;
`;
const BorderLine = styled.hr`
    border-bottom: 0.5px solid #3b3b3b;
    width: 100%;
`;
const SubContainer = styled.div`
    font-size: 14px;
`;
const Name = styled.p`
    color: #fff;
    font-size: 18px;
    margin-bottom: 2px;
`;
const Designation = styled.p`
    color: #28cc8b;
    font-size: 14px;
    margin-bottom: 2px;
    text-transform: capitalize;
`;
const BioContainer = styled.div`
    color: #ffff;
    margin-top: 30px;
`;
const BioHead = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    @media all and (max-width: 480px) {
        /* font-size: 10px; */
    }
    @media all and (max-width: 360px) {
        /* font-size: 8px; */
    }
`;
const BioPara = styled.p`
    color: white;
    margin-top: 10px;
    font-size: 14px;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
        /* font-size: 10px; */
    }
`;
const DetailsContainer = styled.div`
    margin-top: 15px;
`;
const PersonDetails = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    @media all and (max-width: 480px) {
        grid-column-gap: 15px;
    }
    @media all and (max-width: 480px) {
        grid-column-gap: 10px;
    }
`;
const Item = styled.li`
    font-size: 15px !important;
`;
const Heading = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 2px;
    @media all and (max-width: 480px) {
    }
`;
const ParaDetail = styled.p`
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
        /* font-size: 10px; */
    }
`;
const Email = styled.p`
    font-size: 14px;
    color: #fff;
    /* text-transform: capitalize; */

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
        /* font-size: 10px; */
    }
`;

const SocialMediaCont = styled.div`
    margin: 20px 0;
`;
const MediaHeading = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 10px;
    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;
const MediaList = styled.ul`
    display: flex;
`;
const MediaItem = styled.a`
    cursor: pointer;
    width: 8%;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`;
const LinkCont = styled.div`
    margin-bottom: 10px;
`;
const LinkHead = styled.h6`
    font-size: 13px;
    color: #b7b7b7;
    margin-bottom: 5px;
    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;
const LinkDiv = styled.div`
    padding: 10px 5px;
    width: 30%;
    text-align: center;
    background-color: #14232e;
    border-radius: 11px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 13px;
`;
const Clip = styled.div`
    cursor: pointer;
    background: #1f2934;
    padding: 3px;
    border-radius: 6px;
    width: 6%;
`;
const ImageClip = styled.img`
    display: block;
    width: 100%;
`;
const Url = styled.span`
    font-size: 12px;
    margin-left: 12px;
    color: #fff;
`;
const ImageContainer = styled.div`
    margin-bottom: 20px;
`;
const ContentList = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    @media all and (max-width: 980px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
const Li = styled.li`
    height: 150px;
    width: 150px;
    margin-bottom: 15px;
    border-radius: 10px;
    @media all and (max-width: 1280px) {
        height: 120px;
        width: 120px;
    }
    @media all and (max-width: 1080px) {
        height: 103px;
        width: 103px;
    }
    @media all and (max-width: 980px) {
        width: unset;
        height: 140px;
    }
    @media all and (max-width: 480px) {
        /* height: 102px; */
        height: 146px;
    }
    @media all and (max-width: 640px) {
    }
`;
const ItemImages = styled.div`
    cursor: pointer;
    height: 100%;
`;
const MediaImg = styled.img`
    object-fit: cover;
    border-radius: 12px;
    height: 100%;
`;
const VideoThumbnail = styled.div`
    position: relative;
    cursor: pointer;
    height: 100%;
`;
const ThumbContainer = styled.div`
    height: 100%;
`;
const ThumbnailImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    border-radius: 12px;
`;
const PlayButton = styled.div`
    position: absolute;
    top: 32%;
    left: 35%;
    width: 35%;
    @media all and (max-width: 980px) {
        left: 41%;
        width: 22%;
    }
    @media all and (max-width: 480px) {
        left: 36%;
        width: 30%;
        top: 36%;
    }
`;
const PlayImg = styled.img``;
const FacultyBottom = styled.div`
    background: #1f2934;
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 100%;
    padding: 10px 25px 25px;
`;

const CancelVerifyBtn = styled.button`
    cursor: pointer;
    width: 100%;
    padding: 9.5px;
    border: 1.5px solid #fe673a;
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
`;
const Cancelled = styled.div`
    cursor: default;
    width: 100%;
    padding: 9.5px;
    font-size: 14px;
    background: #fe673a;
    color: #fff;
    border-radius: 6px;
    text-align: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    text-align: center;
    font-size: 14px;
`;
const RejectBttn = styled.div`
    border-radius: 6px;
    border: 1px solid #fe673a;
    padding: 8px 6px;
    width: 50%;
    margin-right: 10px;
    cursor: pointer;
`;
const VerifyBttn = styled.div`
    border-radius: 6px;
    border: 1px solid #28cc8b;
    padding: 8px 6px;
    width: 50%;
    background: #28cc8b;
    cursor: pointer;
`;
const Cover = styled.div`
    position: relative;
`;
const VerifiedContainer = styled.div`
    width: 20px;
    text-align: center;
    margin-bottom: 5px;
`;
const VeifiedIcon = styled.img``;
const StatusContainer = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Status = styled.h4`
    text-align: center;
    color: #28cc8b;
    text-transform: capitalize;
    font-size: 12px;
`;
const Result = styled.span``;
const Reject = styled.p`
    font-size: 12px;
    color: #fe673a;
    text-transform: capitalize;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const ImageDiv = styled.a`
    height: 100%;
`;
const AvailableDate = styled.div`
    margin: 15px 0;
`;
const DateAvailable = styled.p`
    font-size: 14px;
    margin: 0 auto;
    color: #fff;
`;
const PlaceDiV = styled.div`
    margin: 15px 0;
`;
const ModeOfSession = styled.div`
    margin: 15px 0;
`;
const Download = styled.div`
    width: 22px;
    height: 22px;
    background-color: red;
    position: fixed;
    top: 30px;
    left: 20px;
    z-index: 2002;
`;
