import "./assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import Store, { Context } from "./components/contexts/Store";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { accountsConfig } from "./axiosConfig";




function App() {
    return (
        <>
            <Router>
                <Store>
                    <MainRouter />
                </Store>
            </Router>
        </>
    );
}
const LogOut = styled.div`
    /* @media all and (max-width: 480px) { */
    @media all and (max-width: 640px) {
        margin-top: 6px;
    }
`;
export default App;
