import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./authentication/AuthRouter";

const MainRouter = () => {
    const {
        state,
        state: { student_data },
        dispatch,
    } = useContext(Context);

    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);

    const [isLoading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    // const { state, dispatch, state: user_data } = useContext(Context);

    useEffect(() => {
        async function fetchUserData() {
            let promise = new Promise((resolve, reject) => {
                let user_data = localStorage.getItem("user_data");
                user_data = JSON.parse(user_data);
                dispatch({
                    type: "UPDATE_USER_DATA",
                    user_data: { ...user_data, is_loading: true },
                });
                uservalidation();
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: { is_loading: false },
                    });
                }, 500);
            });

            let result = await promise;
        }

        fetchUserData();
    }, []);

    useEffect(() => {
        dispatch({
            type: "UPDATE_SEARCH_PARAMS",
            payload: currentParams,
        });
    }, []);

    const uservalidation = () => {
        console.log(
            "***************************************8 user_data",
            user_data
        );
        accountsConfig
            .get("validate/token/", {
                headers: {
                    Authorization: `Bearer ${user_data.access_token}`,
                },
            })
            .then((response) => {
                console.log(response.data);
                const { StatusCode, data } = response.data;
                if (StatusCode === 6001) {
                    navigate("/");
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            is_verified: false,
                        },
                    });
                } else {
                }
            });
    };
    // useEffect(() => {
    //     uservalidation();
    //     console.log(access_token, "heeeee");
    // }, [access_token]);
    return isLoading ? (
        <SectionLoader />
    ) : (
        <Routes>
            <Route
                path="auth/*"
                element={
                    <AuthRoute>
                        <AuthRouter />
                    </AuthRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <AppRouter />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default MainRouter;
