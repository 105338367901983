import React from "react";
import styled from "styled-components";

const Pagination = ({
    itemsPerPage,
    totalItems,
    paginate,
    currentPage,
    total_pages,
    first_item,
    last_item,
}) => {
    const pageNumbers = [];

    for (let i = 1; i <= total_pages; i++) {
        pageNumbers.push(i);
    }
    const handlePrevious = () => {
        if (currentPage != 1) {
            paginate(currentPage - 1);
        }
    };
    const handleNext = () => {
        if (currentPage < total_pages) {
            paginate(currentPage + 1);
        }
    };

    return (
        <>
            <Container>
                <Left>
                    <Pages>
                        {`Showing ${first_item} to ${last_item}  of ${totalItems} entries`}
                    </Pages>
                </Left>

                <Section>
                    <ArrowBtn onClick={handlePrevious}>Previous</ArrowBtn>
                    {pageNumbers.map((number) => (
                        <Round
                            key={number}
                            onClick={() => {
                                paginate(number);
                            }}
                            style={{
                                background:
                                    parseInt(currentPage) === number &&
                                    "#56c082",
                                color:
                                    parseInt(currentPage) === number && "#fff",
                            }}
                        >
                            {number}
                        </Round>
                    ))}
                    <ArrowBtn onClick={handleNext}>Next</ArrowBtn>
                </Section>
            </Container>
        </>
    );
};

export default Pagination;

const Container = styled.div`
    padding: 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 768px) {
        padding: 10px 0;
    }
    @media all and (max-width: 640px) {
        padding: 15px 0 0;
    }
`;

const Left = styled.div``;
const Pages = styled.p`
    color: #fff;
    @media all and (max-width: 640px) {
        display: none;
    }
`;
const Section = styled.div`
    display: flex;
    justify-content: space-between;
    width: 29%;
    margin-left: auto;
    @media all and (max-width: 1280px) {
        width: 39%;
    }
    @media all and (max-width: 1080px) {
        width: 45%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 60%;
    }
    @media all and (max-width: 640px) {
        width: 75%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const Round = styled.span`
    background: #1f2934;
    border: 1px solid #201f1d;
    cursor: pointer;
    font-size: 16px;
    font-family: gordita_medium;
    width: 50px;
    color: #a5accd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
        margin-right: 0;
    }
`;

const ArrowBtn = styled.span`
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    width: 110px !important;
    display: flex;
    height: 45px;
    background: #1f2934;
    border: 1px solid #201f1d;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 1280px) {
        /* width: 75px !important; */
    }
    @media all and (max-width: 980px) {
        /* width: 65px !important; */
    }
    @media all and (max-width: 640px) {
        /* width: 65px !important; */
        /* height: 48px; */
        /* font-size: 10px !important; */
    }
    @media all and (max-width: 480px) {
        height: 40px;
        font-size: 13px;
        width: 79px !important;
    }
`;
