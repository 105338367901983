import axios from "axios";
import React, { useState, useEffect } from "react";
// import ReactPlayer from "react-player";
import styled, { keyframes } from "styled-components";
import $ from "jquery";
// import ReactPlayer from "react-player";
import VideoPlayer from "../applications/video-player/src/VideoPlayer";
import Container from "react-images-viewer/lib/components/Container";


function VideoModal({ isModal, setModal, videoThumnail, videoUrl }) {
    // const videoJsOptions = {
    //     autoplay: true,
    //     controls: true,
    //     sources: [
    //         {
    //             src: videoUrl,
    //             type: "video/mp4",
    //         },
    //     ],
    // };
    const videoJsOptions = {
        autoplay: true,
        controls: false,
    };
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    return (
        isModal && (
            <>
                <BackContainer id="invite-video">
                    <Overlay
                        onClick={() => {
                            setModal(false);
                        }}
                    ></Overlay>
                    <VideoModalContainer isModal={isModal}>
                        <ContainerVideo>
                            <Video className="player-wrapper">
                        
                            <VideoPlayer
                                {...videoJsOptions}
                                    source={videoUrl}
                            />
                            </Video>
                        </ContainerVideo>
                        <CloseIconContainer onClick={() => setModal(false)}>
                            <CloseIcon
                                src={
                                    require("../../assets/images/faculty/cross.svg")
                                        .default
                                }
                                alt=""
                            />
                        </CloseIconContainer>
                    </VideoModalContainer>
                </BackContainer>
            </>
        )
    );
}

export default VideoModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const BackContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
`;
const Video = styled.div`
    position: relative;
`;
const Overlay = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    filter: blur(2px);

    cursor: pointer;
`;

const VideoModalContainer = styled.div`
    /* position: fixed; */
    /* left: -50%;
    top: 50%; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 750px; */
    z-index: 1;
    border-radius: 9px;
    background-color: #000;
    transition: all 0.4s;
`;

const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const CloseIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: -40px;
    width: 34px;
    cursor: pointer;
    @media (max-width: 640px) {
        right: -30px;
        width: 22px;
    }
    @media (max-width: 480px) {
        right: 0;
        top: -28px;
        width: 20px;
    }
`;
const VideoDetail = styled.div`
    margin-top: 5px;
    padding: 15px 15px 25px 15px;
    justify-content: space-between;
    @media (max-width: 480px) {
        padding: 7px 14px;
    }
`;
const VideoTitle = styled.h2`
    font-family: "baloo_paaji_2medium";
    font-size: 14px;
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;
const VideoNumbers = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;
const VideoLikes = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
`;
const LikeImg = styled.img`
    display: block;
    width: 20px;
    transform: translate(0px, -1px);
    @media (max-width: 480px) {
        width: 15px;
    }
    @media (max-width: 360px) {
        width: 10px;
        transform: translate(0px, 0px);
    }
`;
const LikeNum = styled.span`
    margin-left: 10px;
    @media (max-width: 480px) {
        font-size: 14px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
    }
`;
const VideoViews = styled.div`
    display: flex;
    align-items: center;
`;
const ViewImg = styled.img`
    display: block;
    width: 25px;
    transform: translate(0px, -1px);
    @media (max-width: 480px) {
        width: 20px;
    }
    @media (max-width: 360px) {
        width: 15px;
        transform: translate(0px, 0px);
    }
`;
const ViewNum = styled.span`
    margin-left: 10px;
    @media (max-width: 480px) {
        font-size: 14px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
    }
`;
const ContainerVideo = styled.div`
    overflow: hidden;
    border-radius: 20px;
    @media (max-width: 768px) {
        & div {
            width: 600px !important;
            height: unset !important;
        }
    }
    @media (max-width: 640px) {
        & div {
            width: 460px !important;
            /* height: 250px !important; */
        }
    }
    @media (max-width: 480px) {
        & div {
            width: 360px !important;
            /* height: 150px !important; */
        }
    }
`;
