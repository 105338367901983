import React, { Suspense } from "react";
import styled from "styled-components";
import "../../../assets/css/style.css";

// components
import Topbar from "../../includes/Topbar";
import Sidebar from "../../includes/Sidebar";
import { Navigate, Route, Routes } from "react-router-dom";
import Faculty from "../../screens/faculty/Faculty";
import Venues from "../../screens/venues/Venues";
import SectionLoader from "../../helpers/SectionLoader";

export default function AppRouter() {
    return (
        <Container>
            <Topbar />
            <Sidebar />
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route path="/" element={<Navigate to="/faculty" />} />
                    <Route path="/faculty" element={<Faculty />} />
                    <Route path="/venues" element={<Venues />} />
                </Routes>
            </Suspense>
        </Container>
    );
}

const Container = styled.section`
    background: #14232e;
    min-height: 100vh;
    position: relative;
`;
