import React, { useState } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { detailsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function SucessModal({
    setInfobar,
    sucessModal,
    setSucessmodal,
    selectedFaculty,
    reload,
    setReload,
    facultyDetails,
    description,
}) {
    // const {dispatch} = useContext(Context)
    // const verifyFaculty =()=>{
    // 	dispatch({
    // 		type:"UPDATE_USER_DATA",
    // 		user_data:{
    // 			faculty_verify:true
    // 		}
    // 	})
    // }
    const [isLoading, setLoading] = useState(false);

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    return (
        <>
            <Overlay
                style={{ display: sucessModal && "block" }}
                onClick={() => setSucessmodal(false)}
            ></Overlay>
            <Modal
                style={{
                    transform:
                        sucessModal && "translate(-50%, -50%) scale(1,1)",
                }}
            >
                <Container>
                    <Content>
                        <Top>
                            <Head>
                                <ButonTick>
                                    <TickContainer
                                        src={
                                            require("../../../assets/images/sidebar/verified.svg")
                                                .default
                                        }
                                    />
                                </ButonTick>
                                <MainTitle>Successfully Verified</MainTitle>
                            </Head>
                        </Top>
                        <HrLine></HrLine>
                        <Middle>
                            <SubTitle>{description}</SubTitle>
                        </Middle>
                        <ButtonContainer>
                            <VerifyButton
                                onClick={() => {
                                    setSucessmodal(false);
                                    setInfobar(false);
                                }}
                            >
                                Continue
                            </VerifyButton>
                        </ButtonContainer>
                    </Content>
                </Container>
            </Modal>
        </>
    );
}

export default SucessModal;

const Overlay = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    display: none;
`;
const Modal = styled.div`
    width: 35%;
    position: fixed;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0, 0);
    transition: 0.5s;
    border: 1px solid #3b3b3b;
    background: #1f2934;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        width: 38%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 52%;
    }
    @media all and (max-width: 640px) {
        width: 63%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    @media all and (max-width: 360px) {
        width: 90%;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 40px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 20px 40px;
    }
    @media all and (max-width: 480px) {
        padding: 20px 23px;
    }
`;
const Content = styled.div`
    width: 100%;
`;
const Top = styled.div``;
const Head = styled.div`
    display: flex;
    align-items: center;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const HrLine = styled.hr`
    border: 1px solid #3b3b3b;
    margin: 20px 0px;
    @media all and (max-width: 480px) {
        margin: 10px 0px;
    }
`;
const Middle = styled.div``;
const SubTitle = styled.p`
    font-size: 16px;
    color: #ffff;
    @media all and (max-width: 768px) {
        font-size: 12px;
    }
`;
const ButtonContainer = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    font-size: 14px;
    @media all and (max-width: 980px) {
        margin-top: 30px;
    }
    @media all and (max-width: 768px) {
        margin-top: 20px;
    }
`;
const VerifyButton = styled.div`
    border: 1px solid #28cc8b;
    font-size: 16px;
    border-radius: 6px;
    padding: 8px 0px;
    width: 48%;
    background: #28cc8b;
    cursor: pointer;
    @media all and (max-width: 980px) {
        padding: 10px 23px;
    }
    @media all and (max-width: 768px) {
        padding: 8px 18px;
    }
    @media all and (max-width: 640px) {
        padding: 10px;
    }
    @media all and (max-width: 480px) {
        width: 55%;
    }
`;
const ButonTick = styled.div`
    width: 7%;
    margin-right: 15px;
    @media all and (max-width: 480px) {
        width: 12%;
    }
`;
const TickContainer = styled.img`
    display: block;
    width: 100%;
`;
