import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../contexts/Store";

export default function Sidebar({ setSideMenu, sideMenu }) {
    const { state, dispatch } = useContext(Context);
    const [active, setActive] = useState(false);

    const {
        state: { user_data },
    } = useContext(Context);
    const location = useLocation();
    const value = user_data.menu;

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: { menu: false },
                    });
                    // if (state.user_data.menu) {
                    //     setActive(!active);
                    // }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <>
            <Overlay
                style={{ display: user_data.menu && "block" }}
                onClick={() => {
                    user_data.menu(false);
                }}
            ></Overlay>
            <Container type={value} ref={wrapperRef}>
                <Content>
                    <List>
                        <Item
                            to="faculty"
                            className={
                                location.pathname === "/faculty" && "active"
                            }
                            onClick={() => {
                                dispatch({
                                    type: "UPDATE_USER_DATA",
                                    user_data: { menu: false },
                                });
                            }}
                        >
                            <IconContainer className="icon-container">
                                <EventIcon
                                    src={
                                        require("../../assets/images/sidebar/faculty-icon.svg")
                                            .default
                                    }
                                    alt="icon"
                                />
                            </IconContainer>
                            <Event className="event">Faculties</Event>
                        </Item>
                        <Item
                            to="venues"
                            className={
                                location.pathname === "/venues" && "active"
                            }
                            onClick={() => {
                                dispatch({
                                    type: "UPDATE_USER_DATA",
                                    user_data: { menu: false },
                                });
                            }}
                        >
                            <IconContainer className="icon-container">
                                <EventIcon
                                    src={
                                        require("../../assets/images/sidebar/address-icon.svg")
                                            .default
                                    }
                                    alt="icon"
                                />
                            </IconContainer>
                            <Event className="event">Venues</Event>
                        </Item>
                    </List>
                </Content>
            </Container>
        </>
    );
}

const Overlay = styled.div`
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(2px); */
    display: none;
`;
const Container = styled.section`
    padding: 72px 0px 25px;
    /* width: 160px; */
    width: 216px;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    z-index: 10;
    background: #1f2934;
    transition: 0.3s;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 980px) {
        width: 70px;
        &:hover {
            width: 210px;
            .icon-container {
                margin-right: 10px;
            }
            .event {
                display: block;
            }
        }
    }
    @media all and (max-width: 480px) {
        left: ${({ type }) => (type ? "0px" : "-300px")};
        /* display: none; */
        /* left: -100px; */
        width: 210px;
        /* &.active {
            left: 0px;
        } */
        .icon-container {
            margin-right: 10px;
        }
    }
`;
const Content = styled.div``;
const List = styled.ul``;
const Item = styled(Link)`
    display: flex;
    align-items: center;
    padding: 12px 15px 12px 25px;
    cursor: pointer;
    transition: 0.5s ease;
    &.active {
        background: #14232e;
        &::before {
            content: "";
            width: 6px;
            height: 45px;
            background: #26bf87;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
            transition: 0.5s ease;
        }
    }
    @media all and (max-width: 480px) {
    }
`;
const IconContainer = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 20px;
    @media all and (max-width: 980px) {
        width: 20px;
        margin-right: 0px;
    }
    @media all and (max-width: 480px) {
        /* margin-right: 20px; */
    }
`;
const EventIcon = styled.img`
    height: 18px;
`;
const Event = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    @media all and (max-width: 980px) {
        display: none;
    }
    @media all and (max-width: 480px) {
        display: block;
    }
`;
