import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Outlet, useSearchParams } from "react-router-dom";

export default function FilterFunction({ activeClick, setActiveClick, type }) {
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const toggleDrop = () => {
        setOpen(!open);
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <>
            <Container ref={wrapperRef}>
                <FilterFeature onClick={toggleDrop}>
                    <Feature>Filter</Feature>
                    <FilContainer>
                        <FilIcon
                            src={
                                require("../../../assets/images/faculty/filter-icon.svg")
                                    .default
                            }
                            alt="filter-icon"
                        />
                    </FilContainer>
                </FilterFeature>
                {open && (
                    <>
                        <DropDownM>
                            <Choice
                                className={
                                    activeClick === "all" ? "active" : ""
                                }
                                onClick={() => (
                                    setSearchParams({ filter: "all" }),
                                    setActiveClick("all"),
                                    toggleDrop()
                                )}
                            >
                                <Span>
                                    {activeClick === "all" ? (
                                        <SelectedImg
                                            src={
                                                require("../../../assets/images/filter/selected.svg")
                                                    .default
                                            }
                                        />
                                    ) : (
                                        <UnSelectedImg
                                            src={
                                                require("../../../assets/images/filter/unselected.svg")
                                                    .default
                                            }
                                        />
                                    )}
                                </Span>
                                <Text>All</Text>
                            </Choice>
                            <Choice
                                className={
                                    activeClick === "not-verified"
                                        ? "active"
                                        : ""
                                }
                                onClick={() => (
                                    type === "venue" &&
                                        setSearchParams({
                                            is_approved: "pending",
                                        }),
                                    type === "faculty" &&
                                        setSearchParams({
                                            faculty_status: "pending",
                                        }),
                                    setActiveClick("not-verified"),
                                    toggleDrop()
                                )}
                            >
                                <Span>
                                    {activeClick === "not-verified" ? (
                                        <SelectedImg
                                            src={
                                                require("../../../assets/images/filter/selected.svg")
                                                    .default
                                            }
                                        />
                                    ) : (
                                        <UnSelectedImg
                                            src={
                                                require("../../../assets/images/filter/unselected.svg")
                                                    .default
                                            }
                                        />
                                    )}
                                </Span>
                                <Text>Not-Verified</Text>
                            </Choice>
                            <Choice
                                className={
                                    activeClick === "verified" ? "active" : ""
                                }
                                onClick={() => (
                                    type === "venue" &&
                                        setSearchParams({
                                            is_approved: "approved",
                                        }),
                                    type === "faculty" &&
                                        setSearchParams({
                                            faculty_status: "approved",
                                        }),
                                    setActiveClick("verified"),
                                    toggleDrop()
                                )}
                            >
                                <Span>
                                    {activeClick === "verified" ? (
                                        <SelectedImg
                                            src={
                                                require("../../../assets/images/filter/selected.svg")
                                                    .default
                                            }
                                        />
                                    ) : (
                                        <UnSelectedImg
                                            src={
                                                require("../../../assets/images/filter/unselected.svg")
                                                    .default
                                            }
                                        />
                                    )}
                                </Span>
                                <Text>Verified</Text>
                            </Choice>
                            <Choice
                                className={
                                    activeClick === "rejected" ? "active" : ""
                                }
                                onClick={() => (
                                    type === "venue" &&
                                        setSearchParams({
                                            is_approved: "rejected",
                                        }),
                                    type === "faculty" &&
                                        setSearchParams({
                                            faculty_status: "rejected",
                                        }),
                                    setActiveClick("rejected"),
                                    toggleDrop()
                                )}
                            >
                                <Span>
                                    {activeClick === "rejected" ? (
                                        <SelectedImg
                                            src={
                                                require("../../../assets/images/filter/selected.svg")
                                                    .default
                                            }
                                        />
                                    ) : (
                                        <UnSelectedImg
                                            src={
                                                require("../../../assets/images/filter/unselected.svg")
                                                    .default
                                            }
                                        />
                                    )}
                                </Span>
                                <Text>Rejected</Text>
                            </Choice>
                        </DropDownM>
                    </>
                )}
                <Outlet />
            </Container>
        </>
    );
}

const Overley = styled.div`
    /* width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    right: 0%; */
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Container = styled.div`
    position: relative;
`;
const FilterFeature = styled.div`
    width: 75px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border: 1px solid #1f2934;
    border-radius: 5px;
    padding: 2px 8px;
    cursor: pointer;
    position: relative;
`;
const Feature = styled.p`
    color: #fff;
    font-size: 14px;
`;
const FilContainer = styled.span``;
const FilIcon = styled.img``;
const DropDownM = styled.div`
    position: absolute;
    z-index: 200;
    right: 0%;
    display: flex;
    flex-direction: column;
    opacity: 1;
    box-shadow: 0px 4px 60px rgb(0 0 0 / 50%);
    overflow: visible;
    background: #1f2934;
    max-height: 430px;
    transition: 0.5s ease;
    border-radius: 5px;
    width: 250px;
    padding: 10px;
    border: 1px solid #28292d;
    @media all and (max-width: 360px) {
        width: 230px;
    }
`;
const Choice = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    &.active {
        background: #14232e;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #26bf87;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
`;
const Span = styled.span`
    display: inline-block;
    margin-right: 10px;
    width: 20px;
`;
const SelectedImg = styled.img``;
const UnSelectedImg = styled.img`
    padding-right: 5px;
    margin-left: 2px;
`;
const Text = styled.p`
    color: #fff;
    font-size: 12px;
`;
