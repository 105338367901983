import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";
import { getDateStr } from "../../helpers/functions";

//components

import ReactPaginate from "react-paginate";
import VenueDetails from "../../includes/venues/VenueDetails";
import VideoModal from "../../genaral/VideoModal";
import { Context } from "../../contexts/Store";
import { useContext } from "react";
import { detailsConfig } from "../../../axiosConfig";
import FilterFunction from "../../includes/filter/FilterFunction";
import SectionLoader from "../../helpers/SectionLoader";
import Pagination from "../../helpers/Pagination";

export default function Venues() {
    // const [cards, setCards] = useState(data.slice(0, 28));
    const [infoBar, setInfobar] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModal, setModal] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState([]);
    const [venueReload, setVenueReload] = useState(false);
    const [venues, setVenues] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [activeClick, setActiveClick] = useState("all");
    const [paginationData, setPaginationData] = useState([]);
    const [currentVideo, setCurrentVideo] = useState("");
    const [activeVideo, setActiveVideo] = useState("");
    const currentDate = new window.Date();

    // pagination_______________________

    const [pageNumber, setPageNumber] = useState(1);
    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const displayUsers = venues.map((item) => (
        <Card
            key={item.id}
            onClick={() => {
                setInfobar(true);
                setSelectedVenue(item);
            }}
        >
            <LSide>
                <PhotoContainer>
                    <PlaceImg
                        src={require("../../../assets/images/venues/places/noplace.jpg")}
                        alt="place-image"
                    />
                </PhotoContainer>
            </LSide>
            <RSide>
                <Details>
                    <Name>{item.venue_name}</Name>
                    <Place>
                        <PinContainer>
                            <PinImg
                                src={
                                    require("../../../assets/images/venues/location.svg")
                                        .default
                                }
                                alt="location"
                            />
                        </PinContainer>
                        <Location>{item.place}</Location>
                    </Place>
                    <Date>
                        <ClockContainer>
                            <ClockImg
                                src={
                                    require("../../../assets/images/venues/clock.svg")
                                        .default
                                }
                                alt="clock"
                            />
                        </ClockContainer>
                        <AppliedOn>
                            {getDateStr(currentDate) ===
                            getDateStr(item.available_date)
                                ? "Today"
                                : getDateStr(item.available_date)}
                        </AppliedOn>
                    </Date>
                </Details>
                <Badge>
                    {/* {item.is_approved !== "" && (
                            <ImgContainer>
                                <VerifyImg
                                    src={item.image}
                                    alt="verify"
                                />
                            </ImgContainer>
                        )} */}
                    {item.is_approved ===
                    "pending" ? null : item.is_approved === "approved" ? (
                        <ImgContainer>
                            <VerifyImg
                                src={
                                    require("../../../assets/images/faculty/tick.svg")
                                        .default
                                }
                                alt="verify"
                            />
                        </ImgContainer>
                    ) : (
                        <ImgContainer>
                            <VerifyImg
                                src={
                                    require("../../../assets/images/faculty/cross.svg")
                                        .default
                                }
                                alt="rejected"
                            />
                        </ImgContainer>
                    )}
                    <Result>
                        {item.is_approved ===
                        "pending" ? null : item.is_approved == "approved" ? (
                            <Check>
                                {/* {item.is_approved} */}
                                verified
                            </Check>
                        ) : (
                            <Reject>{item.is_approved}</Reject>
                        )}
                    </Result>
                </Badge>
            </RSide>
        </Card>
    ));
    // const pageCount = Math.ceil(paginationData.total_pages);
    // const changePage = (event) => {
    //     const newOffset = (event.selected * itemsPerPage) % items.length;
    //     console.log(
    //         `User requested page number ${event.selected}, which is offset ${newOffset}`
    //     );

    //     setPageNumber(newOffset);
    // };
    const {
        state,
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (infoBar) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [infoBar]);

    useEffect(() => {
        const filterparams = getFilterParams();
        setLoading(true);
        detailsConfig
            .get(`api/v1/web/venues/`, {
                params: {
                    page: pageNumber,
                    is_approved: filterparams.is_approved,
                },
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setVenues(data);
                    setPaginationData(pagination_data);
                    // setFilterData(data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [infoBar, pageNumber, searchParams]);

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("is_approved") === "pending" &&
            (filterParams.is_approved = "pending");
        searchParams.get("is_approved") === "approved" &&
            (filterParams.is_approved = "approved");
        searchParams.get("is_approved") === "rejected" &&
            (filterParams.is_approved = "rejected");
        return filterParams;
    };
    return isLoading ? (
        <SectionLoader />
    ) : (
        <Container
            onClick={() =>
                dispatch({
                    type: "UPDATE_TOGGLE_MENU",
                    payload: !state.toggleMenu,
                })
            }
        >
            {/* <EkeralaHelmet title="Venues Page" /> */}
            <Wrapper className="wrapper">
                <Content>
                    <Top>
                        <Left>
                            <Heading>Venues</Heading>
                        </Left>
                        <Right>
                            <FilterFunction
                                type="venue"
                                activeClick={activeClick}
                                setActiveClick={setActiveClick}
                            />
                        </Right>
                    </Top>
                    <Bottom>
                        <ListCover>
                            <List>
                                {displayUsers}

                                {/* <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={paginationData.total_pages}
                                    onPageChange={changePage}
                                    containerClassName={"paginationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    renderOnZeroPageCount={null}
                                /> */}
                            </List>
                        </ListCover>
                    </Bottom>
                    {paginationData.total_pages > 1 && (
                        <Pagination
                            currentPage={paginationData.current_page}
                            itemsPerPage={30}
                            totalItems={paginationData.total_items}
                            total_pages={paginationData.total_pages}
                            paginate={paginate}
                            first_item={paginationData.first_item}
                            last_item={paginationData.last_item}
                        />
                    )}
                </Content>
            </Wrapper>

            <VenueContainer type={infoBar}>
                <Overlay
                    className={infoBar && "active"}
                    onClick={() => setInfobar(false)}
                ></Overlay>
                <VenueDetails
                    infoBar={infoBar}
                    setInfobar={setInfobar}
                    selectedVenue={selectedVenue}
                    venueReload={venueReload}
                    setVenueReload={setVenueReload}
                    setModal={setModal}
                    isModal={isModal}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                    setActiveVideo={setActiveVideo}
                    activeVideo={activeVideo}
                />
                {/* <VideoModal
                    setModal={setModal}
                    isModal={isModal}
                    videoUrl={currentVideo}
                /> */}
            </VenueContainer>
        </Container>
    );
}
const BackContainer = styled.div`
    z-index: 50;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    display: ${(props) => (props.infoBar ? "block" : "none")};
`;
const Overlay = styled.div`
    /* z-index: 40;
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100vh;
    filter: blur(1px); */
    z-index: 0;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    filter: blur(1px);
    /* background: rgba(0, 0, 0, 0.2); */
    backdrop-filter: blur(2px);
    display: none;
    &.active {
        display: block;
    }
`;
const VenueContainer = styled.div`
    /* position: fixed;
    top: 0;
    right: ${({ type }) => (type ? "0" : "-35%")};
    width: 35%;
    height: 100%;
    background: #1f2934;
    z-index: 1000; */
    z-index: 60;
    top: 0;
    right: ${({ type }) => (type ? "0" : "-100%")};
    transition: all 0.5s ease;
    position: fixed;
    width: 35%;
    height: 100vh;
    background: #1f2934;
    @media all and (max-width: 1280px) {
        width: 40%;
    }
    @media all and (max-width: 980px) {
        width: 58%;
    }
    @media all and (max-width: 768px) {
        width: 75%;
    }
    @media all and (max-width: 640px) {
        width: 94%;
    }
`;

const Container = styled.section`
    background: #14232e;
    min-height: 100vh;
    position: relative;
`;
const Wrapper = styled.section``;
const Content = styled.div`
    /* padding: 9px 15px 10px; */
    padding: 9px 15px 0px;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;
const Left = styled.div``;
const Heading = styled.h4`
    color: #9c9c9c;
    font-size: 18px;
`;
const Right = styled.div``;

const Bottom = styled.div`
    .paginationBttns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }
    .paginationBttns a {
        cursor: pointer;
        background: #1f2934;
        font-size: 12px;
        padding: 11px 20px;
        min-width: 35px;
        border-radius: 5px;
        color: #fff;
        border: 1px solid #201f1d;
        margin: 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s ease;
        @media all and (max-width: 480px) {
            padding: 8px 16px;
        }
        @media all and (max-width: 360px) {
            padding: 8px 8px;
        }
    }
    .paginationBttns a:hover {
        color: #28cc8b;
    }
    .paginationActive a {
        background-color: #28cc8b;
    }
    .paginationDisabled {
        opacity: 0.5;
    }
    .paginationDisabled a:hover {
        cursor: default;
        color: #fff !important;
    }
    .nextBttn {
        margin: 0px 0px 5px 5px !important;
    }
`;
const ListCover = styled.div`
    min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 768px) {
        min-height: calc(100vh - 179px);
        max-height: calc(100vh - 179px);
    }
`;
const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    margin-bottom: 8px;

    /* min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px); */

    @media all and (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media all and (max-width: 768px) {
        grid-gap: 7px;
    }
    @media all and (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media all and (max-width: 480px) {
        /* min-height: calc(100vh - 178px);
        max-height: calc(100vh - 178px); */
    }
`;
const Card = styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #1f2934;
    width: 100%;
    padding: 10px;
    border: 1px solid #1f2934;
    border-radius: 4px;
    &:hover {
        border: 1px solid #28cc8b;
    }
    @media all and (max-width: 768px) {
        padding: 8px 10px;
    }
`;
const LSide = styled.div`
    margin-right: 15px;
`;
const PhotoContainer = styled.div`
    width: 50px;
    min-height: 50px;
    border-radius: 50%;
    min-width: 50px;
    max-height: 50px;
    overflow: hidden;
    @media all and (max-width: 360px) {
        width: 45px;
        min-height: 45px;
        min-width: 45px;
        max-height: 45px;
    }
`;
const PlaceImg = styled.img`
    border-radius: 50%;
`;
const RSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Details = styled.div`
    width: 100%;
`;
const Name = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
    text-transform: capitalize;

    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;
const Place = styled.div`
    display: flex;
`;
const PinContainer = styled.span`
    display: inline-block;
    width: 12px;
    margin-right: 3px;
`;
const PinImg = styled.img``;
const Location = styled.p`
    color: #28cc8b;
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: capitalize;

    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const Date = styled.div`
    display: flex;
    align-items: center;
`;
const ClockContainer = styled.span`
    width: 14px;
    display: inline-block;
    margin-right: 8px;
`;
const ClockImg = styled.img``;
const AppliedOn = styled.p`
    font-size: 12px;
    color: #afafaf;
`;
const Badge = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const ImgContainer = styled.div`
    width: 20px;
    margin-bottom: 5px;
`;
const VerifyImg = styled.img``;
const Result = styled.span``;
const Check = styled.p`
    font-size: 12px;
    color: #26bf87;
    text-transform: capitalize;
`;
const Reject = styled.p`
    font-size: 12px;
    color: #fe673a;
    text-transform: capitalize;
`;
