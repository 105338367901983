import axios from "axios";
import React from "react";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef } from "react";
// import VideoPlayer from "../applications/video-player/src/VideoPlayer";
import ReactPlayer from 'react-player'
import { useState } from "react";


// import $ from "jquery";

function Mediamodal({ isModal, setModal, videoUrl }) {
    const videoJsOptions = {
        autoplay: true,
        controls: false,
      };

const [windowWidth , setWindowWidth] = useState()

      useEffect(() => {
        function handleResize() {
          setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
      let styles = {};
      if(window.innerWidth <= 480){
        styles=290
        
      } else if(window.innerWidth <= 640){
        styles=400
        
      }  else if (window.innerWidth <= 768) {
        styles=500
        
      }   
      else {
        styles=635
        
      }
      

    
   
      
      
  return (
    isModal && (
      <>
        <BackContainer id="invite-video">
          <Overlay onClick={() => setModal(false)}></Overlay>
          <VideoModalContainer style={styles}>
           
            <Video className="player-wrapper">
            <ReactPlayer url={videoUrl} controls
            width={styles}
            />
            </Video>
            <CloseIconContainer onClick={() => setModal(false)}>
              <CloseIcon
                src={
                  "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/close.svg"
                }
                alt="close icon"
              />
            </CloseIconContainer>
          </VideoModalContainer>
        </BackContainer>
      </>
    )
  );
}

export default Mediamodal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const BackContainer = styled.div`
  position: fixed;

  transition: 0.3s;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0;
  animation-name: ${videoAnimation};
  animation-duration: 0.3s;
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
`;

const VideoModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
  background-color: #000;
  width: unset;
  @media (max-width: 480px) {
    width: unset;
  }
`;
const Video = styled.div`
  position: relative;
`;
const CloseIcon = styled.img`
  display: block;
  width: 100%;
`;
const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: -35px;
  width: 25px;
  cursor: pointer;
  @media (max-width: 640px) {
    right: -30px;
    width: 22px;
  }
  @media (max-width: 480px) {
    right: 0;
    top: -28px;
    width: 20px;
  }
`;
