import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import $, { data } from "jquery";
// import EkeralaHelmet from "../../helpers/EkeralaHelmet";
//components
import FilterFunction from "../../includes/filter/FilterFunction";
import ReactPaginate from "react-paginate";
import FacultyDetails from "../../includes/faculty/FacultyDetails";
import { Context } from "../../contexts/Store";
import { useContext } from "react";
import { detailsConfig } from "../../../axiosConfig";
import Loader from "../../helpers/Loader";
import SectionLoader from "../../helpers/SectionLoader";
import Pagination from "../../helpers/Pagination";
import { getDateStr } from "../../helpers/functions";
import VideoModal from "../../genaral/VideoModal";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";

export default function Faculty() {
    // const [filterTextvalue, updateFilterText] = useState("all");
    const [filteredData, setFilteredData] = useState();
    // const [cards, setCards] = useState(data.slice(0, 28));
    const [infoBar, setInfobar] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedFaculty, setSelectedFaculty] = useState([]);
    // const [faculty, setFaculty] = useState();
    const [reload, setReload] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [activeClick, setActiveClick] = useState("all");
    const [filterData, setFilterData] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [isModal, setModal] = useState(false);
    const [activeVideo, setActiveVideo] = useState("");
    const currentDate = new window.Date();

    // pagination_______________________
    const [pageNumber, setPageNumber] = useState(1);
    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    // const pageCount = Math.ceil(faculties.length / usersPerPage);
    // const changePage = ({ selected }) => {
    //     setPageNumber(selected);
    // };
    const {
        state,
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (infoBar) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [infoBar]);

    useEffect(() => {
        const filterparams = getFilterParams();
        setLoading(true);
        detailsConfig
            .get(`api/v1/web/faculties/`, {
                params: {
                    page: pageNumber,
                    faculty_status: filterparams.faculty_status,
                },
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setFaculties(data);
                    setFilterData(data);
                    setPaginationData(pagination_data);
                } else if (StatusCode === 6001) {
                    setLoading(true);
                } else {
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [infoBar, pageNumber, searchParams]);

    // }, [activeClick]);
    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("faculty_status") === "pending" &&
            (filterParams.faculty_status = "pending");
        searchParams.get("faculty_status") === "approved" &&
            (filterParams.faculty_status = "approved");
        searchParams.get("faculty_status") === "rejected" &&
            (filterParams.faculty_status = "rejected");
        return filterParams;
    };

    const videoJsOptions = {
        autoplay: true,
        controls: false,
    };

    const displayUsers = faculties.map((item) => (
        <Card
            key={item.id}
            onClick={() => {
                setInfobar(true);
                setSelectedFaculty(item);
            }}
        >
            <LeftSection>
                <PhotoContainer>
                    <PersonImg
                        src={require("../../../assets/images/faculty/person/noimage.jpg")}
                        alt="person-image"
                    />
                </PhotoContainer>
            </LeftSection>
            <RightSection>
                <Details>
                    <Name>{item.name}</Name>
                    <Designation>{item.profession}</Designation>
                    <Date>
                        <ClockContainer>
                            <ClockImg
                                src={
                                    require("../../../assets/images/faculty/clock.svg")
                                        .default
                                }
                                alt="clock"
                            />
                        </ClockContainer>
                        <AppliedOn>
                            {getDateStr(currentDate) ===
                            getDateStr(item.available_date)
                                ? "Today"
                                : getDateStr(item.available_date)}
                        </AppliedOn>
                    </Date>
                </Details>
                <Badge>
                    {item.faculty_status ===
                    "pending" ? null : item.faculty_status === "approved" ? (
                        <ImgContainer>
                            <VerifyImg
                                src={
                                    require("../../../assets/images/faculty/tick.svg")
                                        .default
                                }
                                alt="verify"
                            />
                        </ImgContainer>
                    ) : (
                        <ImgContainer>
                            <VerifyImg
                                src={
                                    require("../../../assets/images/faculty/cross.svg")
                                        .default
                                }
                                alt="rejected"
                            />
                        </ImgContainer>
                    )}

                    <Result>
                        {item.faculty_status ===
                        "pending" ? null : item.faculty_status ===
                          "approved" ? (
                            <Check>
                                {/* {item.is_approved} */}
                                verified
                            </Check>
                        ) : (
                            <Reject>{item.faculty_status}</Reject>
                        )}
                    </Result>
                </Badge>
            </RightSection>
        </Card>
    ));

    return isLoading ? (
        <SectionLoader />
    ) : (
        <Container
            className="active-modal"
            onClick={() =>
                dispatch({
                    type: "UPDATE_TOGGLE_MENU",
                    payload: !state.toggleMenu,
                })
            }
        >
            <Wrapper className="wrapper">
                <Content>
                    <Top>
                        <Left>
                            <Heading>Faculties</Heading>
                        </Left>
                        <Right>
                            <FilterFunction
                                type="faculty"
                                activeClick={activeClick}
                                setActiveClick={setActiveClick}
                            />
                        </Right>
                    </Top>
                    <Bottom>

                        <ListCover>
                            <List>
                                {" "}
                                {displayUsers}
                                {/* <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={paginationData.total_pages}
                                    // onPageChange={changePage}
                                    containerClassName={"paginationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> */}
                            </List>
                        </ListCover>
                    </Bottom>
                    {paginationData.total_pages > 1 && (
                        <Pagination
                            currentPage={paginationData.current_page}
                            itemsPerPage={30}
                            totalItems={paginationData.total_items}
                            total_pages={paginationData.total_pages}
                            paginate={paginate}
                            first_item={paginationData.first_item}
                            last_item={paginationData.last_item}
                        />
                    )}
                </Content>
            </Wrapper>
            <FacultyContainer type={infoBar}>
                <Overlay
                    className={infoBar && "active"}
                    onClick={() => setInfobar(false)}
                ></Overlay>
                <FacultyDetails
                    infoBar={infoBar}
                    setInfobar={setInfobar}
                    selectedFaculty={selectedFaculty}
                    reload={reload}
                    setReload={setReload}
                    setModal={setModal}
                    isModal={isModal}
                    setActiveVideo={setActiveVideo}
                    activeVideo={activeVideo}
                />
                {/* <VideoModal
                    setModal={setModal}
                    isModal={isModal}
                    videoUrl={activeVideo}
                /> */}
            </FacultyContainer>
        </Container>
    );
}

// const BackContainer = styled.div`
//     z-index: 50;
//     position: fixed;
//     top: 0px;
//     left: 0px;
//     width: 100%;
//     height: 100vh;
//     background: rgba(0, 0, 0, 0.2);
//     backdrop-filter: blur(2px);
// `;
const Overlay = styled.div`
    z-index: 0;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    filter: blur(1px);
    /* background: rgba(0, 0, 0, 0.2); */
    backdrop-filter: blur(2px);
    display: none;
    &.active {
        display: block;
    }
`;
const FacultyContainer = styled.div`
    z-index: 60;
    top: 0;
    right: ${({ type }) => (type ? "0" : "-100%")};
    transition: all 0.5s ease;
    position: fixed;
    width: 35%;
    height: calc(100vh - 70px);
    background: #1f2934;
    @media all and (max-width: 1280px) {
        width: 40%;
    }
    @media all and (max-width: 980px) {
        width: 58%;
    }
    @media all and (max-width: 768px) {
        width: 75%;
    }
    @media all and (max-width: 640px) {
        width: 94%;
    }
`;
const Container = styled.section`
    background: #14232e;
    min-height: 100vh;
`;
const Wrapper = styled.section``;
const Content = styled.div`
    /* padding: 9px 15px 15px; */
    padding: 9px 15px 0px;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 980px) {
        margin-bottom: 4px;
    }
`;
const Left = styled.div``;
const Heading = styled.h4`
    color: #9c9c9c;
    font-size: 18px;
`;
const Right = styled.div``;

const Bottom = styled.div`
    .paginationBttns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }
    .paginationBttns a {
        cursor: pointer;
        background: #1f2934;
        font-size: 12px;
        padding: 11px 20px;
        min-width: 35px;
        border-radius: 5px;
        color: #fff;
        border: 1px solid #201f1d;
        margin: 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s ease;
        @media all and (max-width: 480px) {
            padding: 8px 16px;
        }
        @media all and (max-width: 360px) {
            padding: 8px 8px;
        }
    }
    .paginationBttns a:hover {
        color: #28cc8b;
    }
    .paginationActive a {
        background-color: #28cc8b;
    }
    .paginationActive a:hover {
        color: #fff !important;
    }
    .paginationDisabled {
        opacity: 0.5;
    }
    .paginationDisabled a:hover {
        cursor: default;
        color: #fff !important;
    }
    .nextBttn {
        margin: 0px 0px 5px 5px !important;
    }
`;
const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    margin-bottom: 8px;
    /* min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px); */

    @media all and (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media all and (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
const Card = styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #1f2934;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    border: 1px solid #1f2934;
    /* max-height: 100px; */
    &:hover {
        border: 1px solid #28cc8b;
    }
`;
const LeftSection = styled.div`
    margin-right: 15px;
    @media all and (max-width: 768px) {
        margin-right: 7px;
    }
`;
const PhotoContainer = styled.div`
    width: 50px;
    min-height: 50px;
    border-radius: 50%;
    min-width: 50px;
    max-height: 50px;
    overflow: hidden;
    @media all and (max-width: 480px) {
        width: 45px;
        min-height: 45px;
        min-width: 45px;
        max-height: 45px;
    }
`;
const PersonImg = styled.img`
    border-radius: 50%;
    height: 100%;
    width: 100%;
`;
const RightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Details = styled.div`
    width: 100%;
`;
const Name = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
    text-transform: capitalize;
    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;
const Designation = styled.p`
    color: #28cc8b;
    font-size: 12px;
    margin-bottom: 5px;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const Date = styled.div`
    display: flex;
    align-items: center;
`;
const ClockContainer = styled.span`
    width: 12px;
    display: inline-block;
    margin-right: 8px;
    @media all and (max-width: 360px) {
        width: 10px;
    }
`;
const ClockImg = styled.img``;
const AppliedOn = styled.p`
    font-size: 10px;
    color: #afafaf;
    @media all and (max-width: 360px) {
        font-size: 8px;
    }
`;
const Badge = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const ImgContainer = styled.div`
    width: 20px;
    margin-bottom: 5px;
`;
const VerifyImg = styled.img``;
const Result = styled.span``;
const Check = styled.p`
    font-size: 12px;
    color: #26bf87;
    text-transform: capitalize;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const Reject = styled.p`
    font-size: 12px;
    color: #fe673a;
    text-transform: capitalize;
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const ListCover = styled.div`
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
    @media all and (max-width: 360px) {
        /* max-height: calc(100vh - 158px); */
    }
`;
