import axios from "axios";

// #### LIVE SERVER CONFIGS #### //
// export const accountsConfig = axios.create({
//     baseURL: "https://accounts.steyp.com",
// });

// ### (LOCAL IP CONFIGS) ### //

// export const accountsConfig = axios.create({
//     baseURL: "http://192.168.1.83:8000",
// });

//#### DEVELOPER SERVER CONFIGS #### //

export const accountsConfig = axios.create({
    baseURL:
        "https://ekerala-41c203b2-9dd9-700ef917dcde.ekerala.demo.talrop.works/api/v1/accounts/",
});
export const detailsConfig = axios.create({
    baseURL:
        "https://ekerala-41c203b2-9dd9-700ef917dcde.ekerala.demo.talrop.works/",
});
