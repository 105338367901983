const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_STUDENT_DETAILS":
            const student_data = {
                ...state.student_data,
                ...action.student_data,
            };
            localStorage.setItem("student_data", JSON.stringify(student_data));
            return {
                ...state,
                student_data: student_data,
            };
        case "UPDATE_USER_ID":
            return {
                ...state,
                user_id: action.user_id,
            };
        case "UPDATE_TOGGLE_MENU":
            return {
                ...state,
                toggleMenu: action.payload,
            };
        case "UPDATE_STUDENTS":
            return {
                ...state,
                students: action.students,
            };
        case "UPDATE_SEARCH_STUDENTS":
            return {
                ...state,
                search_results: action.search_results,
            };
        case "UPDATE_FILTER":
            return {
                ...state,
                isFilter: !state.isFilter,
            };

        case "UPDATE_SIDEBAR":
            return {
                ...state,
                isSideBar: action.isSideBar,
            };
        case "UPDATE_SEARCH_PARAMS":
            return {
                ...state,
                currentParams: action.payload,
            };

        default:
            return state;
    }
};

export default ChiefReducer;
