import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { detailsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function ConfirmModal({
    setInfobar,
    confirmModal,
    setConfirmModal,
    setIsRejected,
    validation,
    venueSection,
    selectedFaculty,
    selectedVenue,
    reload,
    setReload,
    venueReload,
    setVenueReload,
    setButtonChanger,
    facultyDetails,
    venueDetails,
}) {
    const [isLoading, setLoading] = useState(false);

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const handleFacultyClick = (data) => {
        setLoading(true);
        detailsConfig
            .post(
                `api/v1/web/set-faculty-verification-status/${facultyDetails.id}/`,
                {
                    status: data,
                },

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setReload(!reload);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handleVenueClick = (data) => {
        setLoading(true);
        detailsConfig
            .post(
                `api/v1/web/set-venue-verification-status/${venueDetails.id}/`,
                {
                    status: data,
                },

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setVenueReload(!venueReload);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <>
            <BackContainer
                style={{ display: confirmModal && "block" }}
                onClick={() => setConfirmModal(false)}
            ></BackContainer>
            <Modal
                style={{
                    transform:
                        confirmModal && "translate(-50%, -50%) scale(1,1)",
                }}
            >
                <Container>
                    <Top>
                        {venueSection ? (
                            validation ? (
                                <Head>
                                    <ButonTick>
                                        <TickContainer
                                            src={
                                                require("../../../assets/images/sidebar/reject.svg")
                                                    .default
                                            }
                                        />
                                    </ButonTick>
                                    <MainTitle>Reject this Venue</MainTitle>
                                </Head>
                            ) : (
                                <Head>
                                    <MainTitle>Are you sure ?</MainTitle>
                                </Head>
                            )
                        ) : validation ? (
                            <Head>
                                <ButonTick>
                                    <TickContainer
                                        src={
                                            require("../../../assets/images/sidebar/reject.svg")
                                                .default
                                        }
                                    />
                                </ButonTick>
                                <MainTitle>Reject this Faculty</MainTitle>
                            </Head>
                        ) : (
                            <Head>
                                <MainTitle>Are you sure ?</MainTitle>
                            </Head>
                        )}
                    </Top>
                    <HrLine></HrLine>
                    <Middle>
                        {venueSection ? (
                            validation ? (
                                <SubTitle>
                                    Do you want to reject this Venue
                                </SubTitle>
                            ) : (
                                <SubTitle>
                                    Do you want to cancel the verification of
                                    this Venue
                                </SubTitle>
                            )
                        ) : validation ? (
                            <SubTitle>
                                Do you want to reject this faculty
                            </SubTitle>
                        ) : (
                            <SubTitle>
                                Do you want to cancel the verification of this
                                faculty
                            </SubTitle>
                        )}
                    </Middle>
                    {venueSection ? (
                        <ButtonContainer>
                            <RejectButton
                                onClick={() => {
                                    setInfobar(false);
                                    setConfirmModal((prev) => !prev);
                                    // handleVenueClick("pending");
                                }}
                            >
                                No
                            </RejectButton>
                            <VerifyButton
                                onClick={() => {
                                    setInfobar(false);
                                    setConfirmModal(false);
                                    setIsRejected(true);
                                    handleVenueClick("rejected");
                                }}
                            >
                                Yes
                            </VerifyButton>
                        </ButtonContainer>
                    ) : (
                        <ButtonContainer>
                            <RejectButton
                                onClick={() => {
                                    setInfobar(false);
                                    setConfirmModal((prev) => !prev);
                                    handleFacultyClick("pending");
                                    setButtonChanger(true);
                                }}
                            >
                                No
                            </RejectButton>
                            <VerifyButton
                                onClick={() => {
                                    setInfobar(false);
                                    setConfirmModal(false);
                                    setIsRejected(true);
                                    handleFacultyClick("rejected");
                                    setButtonChanger(true);
                                }}
                            >
                                Yes
                            </VerifyButton>
                        </ButtonContainer>
                    )}
                </Container>
            </Modal>
        </>
    );
}

export default ConfirmModal;

const BackContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    display: none;
`;
const Modal = styled.div`
    width: 35%;
    position: fixed;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0, 0);
    transition: 0.3s;
    border: 1px solid #3b3b3b;
    background: #1f2934;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        width: 38%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 52%;
    }
    @media all and (max-width: 640px) {
        width: 63%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    @media all and (max-width: 360px) {
        width: 90%;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 40px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 20px 40px;
    }
`;
const Top = styled.div``;
const Head = styled.div`
    display: flex;
    align-items: center;
`;
const ButonTick = styled.div`
    width: 7%;
    margin-right: 15px;
    @media all and (max-width: 480px) {
        width: 12%;
    }
`;
const TickContainer = styled.img`
    display: block;
    width: 100%;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const HrLine = styled.hr`
    border: 0.5px solid #3b3b3b;
    margin: 20px 0px;
    @media all and (max-width: 480px) {
        margin: 10px 0px;
    }
`;
const SubTitle = styled.p`
    font-size: 16px;
    color: #ffff;
`;
const Middle = styled.div``;
const ButtonContainer = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    @media all and (max-width: 480px) {
        margin-top: 20px;
    }
`;
const RejectButton = styled.div`
    font-size: 16px;
    border: 1px solid #fe673a;
    border-radius: 6px;
    padding: 8px 0px;
    width: 49%;
    cursor: pointer;
    @media all and (max-width: 480px) {
        padding: 6px 30px;
    }
`;
const VerifyButton = styled.div`
    font-size: 16px;
    border: 1px solid #28cc8b;
    border-radius: 6px;
    padding: 8px 0px;
    width: 49%;
    background: #28cc8b;
    cursor: pointer;
    @media all and (max-width: 480px) {
        padding: 6px 30px;
    }
`;
