import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../contexts/Store";

export default function Topbar() {
    const { state, dispatch, state: user_data } = useContext(Context);
    const [open, setOpen] = useState(false);
    const [sideMenu, setSideMenu] = useState(false);
    const [isClick, setClick] = useState(false);

    const toggleDrop = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <Container>
            <Content>
                <Left>
                    <HeaderIcon
                        onClick={() => {
                            setSideMenu(!sideMenu);
                            setClick(!isClick);
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: { menu: isClick },
                            });
                        }}
                        src={
                            require("../../assets/images/header/menu.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <LogoContainer>
                        <LogoImg
                            // onClick={() => {
                            //     window.location.pathname = "/dashboard";
                            // }}
                            src={
                                require("../../assets/images/header/ekerala3.svg")
                                    .default
                            }
                            alt="ekerala-logo"
                        />
                        <HamImg
                            src={
                                require("../../assets/images/header/hamburger.svg")
                                    .default
                            }
                            alt="hamburger"
                        />
                    </LogoContainer>
                    {/* <SearchBar>
                        <SearchContainer>
                            <SearchIcon
                                src={
                                    require("../../assets/images/header/search-icon.svg")
                                        .default
                                }
                                alt="search-icon"
                            />
                        </SearchContainer>
                        <SearchBox type="text" placeholder="Search..." />
                    </SearchBar> */}
                </Left>
                <Right>
                    <Icons>
                        <IconContainer>
                            {/* <MsgIcon
                                src={
                                    require("../../assets/images/header/the_envelope.svg")
                                        .default
                                }
                                alt="envelope-icon"
                            /> */}
                        </IconContainer>
                        <IconContainer>
                            {/* <NotificationIcon
                                src={
                                    require("../../assets/images/header/bell.svg")
                                        .default
                                }
                                alt="bell-icon"
                            /> */}
                        </IconContainer>
                    </Icons>
                    <PersonInfo onClick={toggleDrop} ref={wrapperRef}>
                        <CircleContainer>
                            <PhotoContainer>
                                <PersonImg
                                    src={
                                        require("../../assets/images/header/person-20417.svg")
                                            .default
                                    }
                                    alt="person-20417"
                                />
                            </PhotoContainer>
                        </CircleContainer>
                        <PersonName className="top-name">
                            {/* {state.user_data.name} */}Mark Anderson
                        </PersonName>
                        <ArrowContainer>
                            <DownArrow
                                src={
                                    require("../../assets/images/header/down-arrow1.svg")
                                        .default
                                }
                                alt="down-icon"
                            />
                        </ArrowContainer>
                        {open && (
                            <>
                                <DropFunction>
                                    <PersonName className="drop-name"></PersonName>
                                    <HrLine className="hr-line"></HrLine>
                                    <LogOut
                                        to="/auth/logout/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch({
                                                type: "UPDATE_USER_DATA",
                                                user_data: {
                                                    is_verified: false,
                                                },
                                            });
                                            navigate("/");
                                            // window.location = "/";
                                        }}
                                    >
                                        <Text>Logout</Text>
                                    </LogOut>
                                </DropFunction>
                            </>
                        )}
                    </PersonInfo>
                </Right>
            </Content>
            {/* <Sidebar sideMenu={sideMenu} /> */}
        </Container>
    );
}

const Container = styled.section`
    background: #1f2934;
    padding: 13px;
    position: fixed;
    width: 100%;
    z-index: 20;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeaderIcon = styled.img`
    display: none;
    @media (max-width: 480px) {
        margin-right: 15px;
        cursor: pointer;
        display: block;
        width: 19px;
    }
`;
const LogoContainer = styled.h1`
    width: 150px;
    margin-right: 40px;
    @media all and (max-width: 768px) {
        width: 120px;
    }
    @media all and (max-width: 640px) {
        margin-right: 10px;
        width: 90px;
    }
    @media all and (max-width: 480px) {
        width: 80px;
    }
`;
const LogoImg = styled.img`
    cursor: pointer;
`;
const HamImg = styled.img`
    display: none;
`;
const SearchBar = styled.div`
    display: flex;
    background: #14232e;
    /* display: flex; */
    align-items: center;
    border-radius: 5px;
    width: 270px;
    transition: all 0.5s ease 0s;
    border: 1px solid #14232e;
    padding: 6px 5px 6px 11px;
    @media all and (max-width: 980px) {
        width: 170px;
    }
    @media all and (max-width: 640px) {
        /* width: 150px; */
        /* width: 100px; */
        display: none;
    }

    @media all and (max-width: 480px) {
    }
`;
const SearchContainer = styled.span`
    width: 16px;
    margin-right: 6px;
`;
const SearchIcon = styled.img``;
const SearchBox = styled.input`
    color: rgb(255, 255, 255);
    font-size: 13px;
    width: 100%;
`;
const Right = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22%;
    @media all and (max-width: 1280px) {
        width: 25%;
    }
    @media all and (max-width: 1080px) {
        width: 31%;
    }
    @media all and (max-width: 980px) {
        width: 25%;
    }
    @media all and (max-width: 768px) {
        width: 30.2%;
    }
    @media all and (max-width: 640px) {
        width: 28%;
    }
    @media all and (max-width: 480px) {
        width: 45%;
    }

    @media all and (max-width: 360px) {
        width: 50%;
    }
`;
const Icons = styled.div`
    display: flex;
    align-items: center;
    border-right: 1px solid #000;
    /* padding: 0 10px 0 0; */
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const IconContainer = styled.span`
    display: block;
    width: 20px;
    cursor: pointer;
    &:first-child {
        margin-right: 30px;
    }
    &:last-child {
        margin-right: 10px;
    }
    /* @media all and (max-width: 1280px) {
        &:first-child {
            margin-right: 0px;
        }
    }
    @media all and (max-width: 480px) {
        &:first-child {
            margin-right: 10px;
        }
    } */
`;
const MsgIcon = styled.img``;
const NotificationIcon = styled.img``;
const PersonInfo = styled.div`
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 208px;
    position: relative;
    transition: all 0.5s ease 0s;
    @media all and (max-width: 1280px) {
        width: 212px;
        /* padding-left: 0; */
    }
    @media all and (max-width: 1080px) {
        width: 216px;
    }
    @media all and (max-width: 640px) {
        width: 160px;
        padding-left: 0;
    }
`;
const CircleContainer = styled.div`
    width: 45px;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #28cc8b;
    display: flex;
    align-items: center;
    @media all and (max-width: 640px) {
        width: 35px;
    }
    @media all and (max-width: 480px) {
        width: 28px;
    }
`;
const PhotoContainer = styled.div`
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #444b53;
`;
const PersonImg = styled.img``;
const PersonName = styled.p`
    font-size: 16px;
    color: #fff;
    font-family: "gordita_medium";
    &.drop-name {
        display: block;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        font-size: 11px;
        &.top-name {
        }
        &.drop-name {
            display: block;
            text-align: center;
            margin-bottom: 6px;
        }
    }
    @media all and (max-width: 640px) {
        /* display: none; */
        font-size: 10px;
    }
    @media all and (max-width: 480px) {
        /* display: none; */
        font-size: 12px;
    }
`;
const ArrowContainer = styled.span`
    /* @media all and (max-width: 480px) { */
    @media all and (max-width: 640px) {
        /* display: none; */
    }
`;
const DownArrow = styled.img``;
const DropFunction = styled.div`
    position: absolute;
    background: #293440;
    padding: 7px 38px;
    border-radius: 3px;
    top: 46px;
    right: 0px;
    cursor: pointer;
    @media all and (max-width: 640px) {
        width: 170px;
    }
`;
const HrLine = styled.hr`
    border-bottom: 1px dashed;
    &.hr-line {
        display: none;
    }
    /* @media all and (max-width: 480px) { */
    @media all and (max-width: 640px) {
        &.hr-line {
            display: block;
        }
    }
`;
const LogOut = styled.div`
    /* @media all and (max-width: 480px) { */
    @media all and (max-width: 640px) {
        margin-top: 6px;
    }
`;
const Text = styled.p`
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
`;
